'use strict';

/**
 * @ngdoc function
 * @name 360StoreAngularApp.controller:LicensesCtrl
 * @description
 * # LicensesCtrl
 * Controller of the 360StoreAngularApp
 */
angular.module('360StoreAngularApp')
	.controller('LicensesCtrl', function ($scope, $location, $route, $uibModal, session, api, BUILD_DATE) {
		const nextDailySchedule = moment().utc().add(1, 'day').set({hour: 2, minute: 0, second: 0});
		/**
		 * @type {?MaintenanceSupport}
		 */
		$scope.support = null;
		$scope.credentials = {
			licenseKey: localStorage.licenseKey,
			registeredTo: localStorage.registeredTo
		};

		// $scope.$on('licenseEntry', function (license) {
		// 	$scope.license = license;
		// });

		/**
		 * On form submission
		 */
		$scope.login = function () {
			api.getRenewalCandidates($scope.credentials.licenseKey, $scope.credentials.registeredTo)
				.then(function (response) {
					$scope.support = response;
					localStorage.setItem('licenseKey', $scope.credentials.licenseKey);
					localStorage.setItem('registeredTo', $scope.credentials.registeredTo);
				});
		};

		$scope.enterDifferentLicenseKey = function () {
			$scope.support = null;
		};

		/**
		 * @param item {MaintenanceOption}
		 * @return the relative and then formatted date when registration ends for `item`
		 */
		$scope.maintenanceEnd = function (item) {
			const when = moment(item.expires);
			return when.fromNow() + '! (' + when.format('M/D/YY') + ')';
		};
		/**
		 * @param item {MaintenanceOption}
		 * @return the relative and then formatted date when auto-renew will trigger for `item`, no earlier than the next daily run.
		 */
		$scope.autoRenewDate = function (item) {
			let when = moment(item.expires);
			if ( when < nextDailySchedule ) {
				when = nextDailySchedule;
			}
			return when.fromNow() + '! (' + when.format('M/D/YY') + ')';
		};

		/**
		 * Sets the `renewMe` for all items.
		 * `renewMe` is a bad name, as this license page is used for more than just manual renewals now.
		 */
		$scope.setAllRenewMe = function (yn) {
			$scope.support.options
				.filter( option => !option.disabled )
				.forEach(option => option.renewMe = yn);
		};

		/**
		 * Should the "disable auto-renew" button be disabled? Think of a worse name, I dare you.
		 */
		$scope.isDisableAutoRenewDisabled = () => {
			return $scope.support.options.find(o => o.autoRenew && o.renewMe);
		};

		/**
		 * Is at least one item checked?
		 */
		$scope.atLeastOneRenewMe = function () {
			return $scope.support.options.find(o => o.renewMe);
		};

		$scope.maintenanceEndAfterRenewal = function (item) {
			return moment(item.renewalDate).toDate();
		};

		$scope.toggle = function (item) {
			if (item.disabled) {
				return;
			}
			item.renewMe = !item.renewMe;
			window.event.stopPropagation();
		};

		/**
		 * @return {MaintenanceOption[]}
		 */
		function renewalOptionsToEnableAutoRenewFor() {
			return $scope.support.options
				.filter(o => o.renewMe)
				.filter(o => !o.autoRenew);
		}

		$scope.autoRenewSavings = () => {
			return renewalOptionsToEnableAutoRenewFor()
				.map(o => o.renewalPrice * 0.1)
				.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
		};

		$scope.updatePaymentInfo = () => {
			$location.path('update-payment-info')
				.search('licenseKey', $scope.credentials.licenseKey)
				.search('registeredTo', $scope.credentials.registeredTo);
		};

		/**
		 * Toggles things on the server, shows a toast, and updates the `$scope.support` with the updated auto-renewal candidates
		 * @param options {MaintenanceOption[]}
		 * @param autoRenewEnabled {boolean}
		 * @return {Promise<*>}
		 * @private
		 */
		async function _toggleAutoRenewEnabled(options, autoRenewEnabled) {
			let cartItemIds = options.map(o => o.cartItemId);
			$scope.support = await api.toggleAutoRenewForCartItemIds($scope.credentials.licenseKey, $scope.credentials.registeredTo, cartItemIds, autoRenewEnabled);
			window.toastr.info('Auto-renew ' + (autoRenewEnabled ? 'enabled' : 'disabled') + ' for ' + options.length + ' items', 'AutoRenew Update');
			return cartItemIds;
		}

		$scope.disableAutoRenewForCheckedOrders = async () => {
			let options = $scope.support.options
				.filter(o => !o.autoRenew || o.autoRenewFailed);
			await _toggleAutoRenewEnabled(options, false);
		};

		$scope.enableAutoRenewForCheckedOrders = async () => {
			await $uibModal.open({
				templateUrl: 'views/modal-confirm-auto-renew-for-existing-orders.html?v=' + BUILD_DATE,
				scope: $scope,
			}).result;
			let options = renewalOptionsToEnableAutoRenewFor();
			let cartItemIds = await _toggleAutoRenewEnabled(options, true);
			if ( window.confirm('Auto-renew has been enabled for ' + cartItemIds.length + ' item' + (cartItemIds.length === 1 ? '' : 's') + '. Review payment information?')) {
				$scope.updatePaymentInfo();
			}
		};

		$scope.manuallyRenewCheckedOrders = function () {
			const requestActions = $scope.support.options
				.filter(function (o) {
					return o.renewMe;
				})
				.map(function (o) {
					return {action: 'Renew', cartItemId: o.cartItemId, registeredTo: o.company};
				});
			api.createCartAction(requestActions)
				.then(function (action) {
					session.addCartAction(action);
					$location.path('/checkout');
				});
		};

		/*
		///////////////////////////////////////////////////////////////////////////
		// INIT
		///////////////////////////////////////////////////////////////////////////
		*/
		const params = $route.current.params;
		if (params.licenseKey) {
			$scope.credentials = params;
			$scope.login();
		}


	});
