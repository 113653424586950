'use strict';

/**
 * @ngdoc filter
 * @name 360StoreAngularApp.filter:urlEncode
 * @function
 * @description
 * # urlEncode
 * Filter in the 360StoreAngularApp.
 */
angular.module('360StoreAngularApp')
	.filter('urlEncode', function () {
		return function (input) {
			if (!input) {
				return '';
			}
			return window.encodeURIComponent(input);
		};
	});
